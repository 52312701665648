@import 'functions';
@import 'variables';

:root,
:host {
  --#{$fa-css-prefix}-style-family-sharp: 'Font Awesome 6 Sharp';
  --#{$fa-css-prefix}-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
}

@font-face {
  font-family: 'Font Awesome 6 Sharp';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-sharp-solid-900.woff2') format('woff2');
}

.fass,
.#{$fa-css-prefix}-solid {
  font-weight: 900;
}
